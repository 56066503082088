export default {
  state: {
    loading: false,
    error: null,
    showDialog: false,
    showSnackbar: false,
    idle: true,
    msg: null,
    token: null,
    dirty: false,
    showDirty: false,
    returnUrl: null
  },
  mutations: {
    setLoading(state, payload) {
      state.idle = !payload;
      if(payload) {
        state.loading = payload;
      }
      else {
        setTimeout(() => {
          if(state.idle) {
            state.loading = payload;
          }
        }, 2000);
      }
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    setShowDialog(state, payload) {
      state.showDialog = payload;
    },
    setShowSnackbar(state, payload) {
      state.showSnackbar = payload;
    },
    setMsg(state, payload) {
      state.msg = payload;
    },
    clearMsg(state) {
      state.msg = null;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    clearToken(state) {
      state.token = null;
    },
    setDirty(state, payload) {
      state.dirty = payload;
    },
    clearDirty(state) {
      state.dirty = null;
    },
    setShowDirty(state, payload) {
      state.showDirty = payload;
    },
    clearShowDirty(state) {
      state.showDirty = null;
    },
    setReturnUrl(state, payload) {
      state.returnUrl = payload;
    },
    clearReturnUrl(state) {
      state.returnUrl = null;
    }
  },
  actions: {
    clearError({commit}) {
      commit('clearError');
    },
    setError({commit}, payload) {
      commit('setError', payload);
    },
    clearMsg({commit}) {
      commit('clearMsg');
    },
    setMsg({commit}, payload) {
      commit('setMsg', payload);
    },
    clearToken({commit}) {
      commit('clearToken');
    },
    setToken({commit}, payload) {
      commit('setToken', payload);
    },
    clearDirty({commit}) {
      commit('clearDirty');
    },
    setDirty({commit}, payload) {
      commit('setDirty', payload);
    },
    clearShowDirty({commit}) {
      commit('clearShowDirty');
    },
    setShowDirty({commit}, payload) {
      commit('setShowDirty', payload);
    },
    clearRetrunUrl({commit}) {
      commit('clearRetrunUrl');
    },
    setRetrunUrl({commit}, payload) {
      commit('setRetrunUrl', payload);
    }
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    error(state) {
      return state.error;
    },
    showDialog(state) {
      return state.showDialog;
    },
    showSnackbar(state) {
      return state.showSnackbar;
    },
    idle(state) {
      return state.idle;
    },
    msg(state) {
      return state.msg;
    },
    token(state) {
      return state.token;
    },
    dirty(state) {
      return state.dirty;
    },
    showDirty(state) {
      return state.showDirty;
    },
    returnUrl(state) {
      return state.returnUrl;
    }
  }
}
