export default {
  computed: {
    company() {
      return this.$store.getters.company;
    },
    companies() {
      return this.$store.getters.companies;
    },
    companyIdx() {
      return this.$store.getters.companyIdx;
    },
    companyTemp() {
      return this.$store.getters.companyTemp;
    },
    companyRegStep() {
      return this.$store.getters.companyRegStep;
    }
  },
  methods: {
    toIsoString(date) {
      let tzo = -date.getTimezoneOffset();
      let dif = tzo >= 0 ? '+' : '-';
      let pad = function (num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm
      };
      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60)
    }
  }
}
