<template>
  <v-app :style="cssProps" style="background-color: white">
    <router-view name="header" style="z-index: 1" />
    <v-main>
      <!--<v-container container--fluid>-->
      <router-view />
      <!--</v-container>-->
    </v-main>
    <sfooter v-if="!$route.path.includes('/ai')"></sfooter>
    <!--<v-footer app></v-footer>-->
    <v-row justify="center" align="center" style="max-height: 0px">
      <v-dialog v-model="showIEAlert" persistent max-width="500px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showIEAlert = false"
                >close</v-icon
              >
              <br />
              <h2 class="mt-2 font-weight-bold headline">
                인터넷 익스플로러 안내
              </h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div>
              인터넷 익스플로러에서는 일부 컨텐츠 또는 기능이<br />
              제대로 구현되지 않을 수 있음을 알려드립니다.<br /><br />
              아래의 브라우저를 이용하시면 쾌적한 서비스 이용이 가능합니다.<br />
              (아이콘을 클릭하시면 브라우저 다운로드 링크로 이동합니다.)
            </div>
            <v-row justify="space-between" class="mt-8">
              <v-col class="text-center">
                <div style="height: 96px">
                  <img src="@/assets/img/google-chrome.png" />
                </div>
                <div>Chrome</div>
              </v-col>
              <v-col class="text-center">
                <div style="height: 96px">
                  <img src="@/assets/img/microsoft-edge.png" />
                </div>
                <div>
                  Edge<br />
                  (Windows 전용)
                </div>
              </v-col>
              <v-col class="text-center">
                <div style="height: 96px">
                  <img src="@/assets/img/apple-safari.png" />
                </div>
                <div>
                  Safari<br />
                  (MacOS 전용)
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn class="rouge white-two--text" @click="showIEAlert = false"
              >닫기</v-btn
            >
            <v-btn
              color="rouge"
              outlined
              @click.native="
                showIEAlert = false;
                $cookies.set('IEAlert', true);
              "
              >다시 보지 않기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center" style="max-height: 0px">
      <v-dialog
        v-model="showLoading"
        persistent
        fullscreen
        gcontent-class="loading-dialog"
        transition="false"
      >
        <v-container class="fill-height">
          <v-row justify="center" align="center" id="loadingContainer">
            <v-progress-circular
              indeterminate
              :size="60"
              :width="5"
              color="red accent-3"
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center" style="max-height: 0px">
      <v-dialog
        v-model="showDirtyDialgo"
        persistent
        content-class="white-two"
        max-width="400px"
      >
        <v-card class="pa-4">
          <v-card-text>
            <div>저장하지 않은 정보가 있습니다.</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="rouge white-two--text"
              @click="showDirtyDialgo = false"
              >닫기</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--:value="OfflineOnly"-->
    <v-snackbar v-model="showSnackbar" :timeout="3000" top>
      {{ $store.getters.msg }}
      <v-btn color="pink" @click="showSnackbar = false"> 닫기 </v-btn>
    </v-snackbar>
    <kakao-button v-if="$route.path !== '/campaign/search'"></kakao-button>
  </v-app>
</template>

<script>
import colorMixin from '@/components/shared/colorMixin';
import sfooter from '@/components/footer/SuperFooter';
import KakaoButton from './components/landing/KakaoButton.vue';

export default {
  name: 'App',
  mixins: [colorMixin],
  components: {
    sfooter,
    KakaoButton,
  },
  data() {
    return {
      snackbar: false,
      showIEAlert: false,
    };
  },
  computed: {
    showLoading: {
      set(loading) {
        this.$store.commit('setLoading', loading);
      },
      get() {
        return this.$store.getters.loading;
      },
    },
    showDirtyDialgo: {
      set(showDirty) {
        this.$store.commit('setShowDirty', showDirty);
      },
      get() {
        return this.$store.getters.showDirty;
      },
    },
    showSnackbar: {
      set(snackbar) {
        this.$store.commit('setShowSnackbar', snackbar);
      },
      get() {
        return this.$store.getters.showSnackbar;
      },
    },
  },
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'ko';
    },
  },
  created() {
    this.$on('online', function () {
      this.showSnackbar = false;
    });
    this.$on('offline', function () {
      this.$store.commit('setMsg', '오프라인 모드입니다.');
      this.showSnackbar = true;
    });
    this.showIEAlert =
      this.$browserDetect.isIE && !this.$cookies.get('IEAlert');
  },
};
</script>

<style lang="scss">
@import './assets/scss/layout.scss';
@import './assets/scss/typo.scss';
@import './assets/scss/slick.scss';

.loading-dialog {
  background-color: #f5f5f5;
  background-color: var(--white-color);
  opacity: 0.2;
}
</style>
