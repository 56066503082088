import Vue from 'vue'
import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
// import axios from 'axios';

import store from '../store'

export default function execute(axios) {
  axios.interceptors.request.use(async (config) => {
    if(firebase.auth().currentUser) {
      let token = await firebase.auth().currentUser.getIdToken(true);
      Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      store.commit('setToken', token);
    }
    else {
      const token = store.getters.token;
      if (token) {
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    store.commit('setLoading', true);
    return config;
  }, function(err) {
    store.commit('setLoading', false);
    return Promise.reject(err);
  });

  axios.interceptors.response.use(response => {
    store.commit('setLoading', false);
    return response;
  }, function(err) {
    store.commit('setLoading', false);

    let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
    console.warn("error", message);
    if(err.config && Object.prototype.hasOwnProperty.call(err.config, "errorHandle") && err.config.errorHandle === false) {
      return Promise.reject(err);
    }

    let bSnackbar = true;
    store.commit('setError', err);
    if (err.response) {
      // if (err.response.data === 'Unauthorized') {
      if ((store.getters.user || {}).email) {
        console.log(err);
        if (403 === err.response.status) {
          store.commit('setMsg', '로그인 시간이 만료되었습니다. 다시 로그인해주세요.');
          store.dispatch('logout');
        }
        else if (409 === err.response.status) {
          store.commit('setMsg', '현재 다른 인터넷 창에서 광고를 등록중입니다. 해당 창에서 계속 진행해주세요.');
          location.href = "/"
        }
        else {
          store.commit('setMsg', err.response.data);
          console.log(err.response.data)
        }
      }
      else {
        bSnackbar = false;
      }
    }
    else if (err.message === 'Network Error'){
      // store.commit('setMsg', '서버장애');
    }
    if (!Vue._.isEmpty(store.getters.msg)) {
      store.commit('setShowSnackbar', bSnackbar);
    }
    return Promise.reject(err);
  });
}