import firebase from '@firebase/app'

const config = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID
};

// export default function () {
//   return firebase.initializeApp(config)
// }
let app = firebase.initializeApp(config);
// let firestore = app.firestore()
//
export default {app}
