<template>
  <!-- <div class="event-right" @click="toggleModal()" id="eventBanner"> -->
  <div class="event-right" id="eventBanner">
    <div class="event-left"></div>
    <!-- <div v-if="$vuetify.breakpoint.mdAndUp" class="eventbannerdesktop container"> -->
    <!-- 이벤트 변경 대응을 위해 배너 이미지를 firebase로 뺐습니다!! -->
    <!-- <img src='https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fevent-2022youtube%2F2022-Youtube_Open-desktop.png?alt=media&token=53c3d6b6-602f-4885-829a-4f8ef75b0d0c'/> -->

    <!-- <router-link to="/signup/" id="signupInsta1"> -->
    <!-- </router-link> -->
    <!-- </div> -->
    <!-- <div v-if="$vuetify.breakpoint.smAndDown" class="eventbannermobile container">
      <img src='https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fevent-2022youtube%2F2022-Youtube_Open-mobile.png?alt=media&token=37c2d168-a3a3-4c55-bd48-f62c938f560a'/>

    </div> -->
    <div v-show="modalOpened" class="modal">
      <div class="modal-body">
        <button class="close" v-on:click="this.modalOpened = false">
          &nbsp;
        </button>
        <button
          v-if="$vuetify.breakpoint.mdAndUp"
          class="primary"
          v-on:click="this.modalOpened = false"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventBanner",
  data() {
    this.modalOpened = false;
    return {
      toggleModal: () => {
        this.modalOpened = !this.modalOpened;
        console.log("closeClicked");
      },
      modalOpened: this.modalOpened,
    };
  },
};
</script>

<style scoped lang="scss">
$event-color-left: #585858;
$event-color-center: #545454;
$event-color-right: #505050;
$event-color-default: #44474d;

.event-left {
  background-color: $event-color-left;
  position: absolute;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 100%;
  z-index: 5;
}

// .event-background div::before {
//   content: "";
//   background-color: #FDC322; (오른쪽컬러)
// }

.event-right {
  background-color: $event-color-right;
  position: relative;
  z-index: 5;
  cursor: pointer;
}

.eventbannerdesktop {
  position: inherit;
  z-index: 6;
  height: 128px;
  text-align: center;
  padding: 0 !important;
  img {
    height: 128px;
    width: 960px;
    margin: 0 -100%;
  }
}

.eventbannermobile {
  position: inherit;
  z-index: 6;
  height: 128px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0;
  img {
    height: 128px;
    width: 960px;
    margin: 0 -100%;
    padding: 0;
  }
}

.modal {
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  &.hide {
    display: none;
  }
  .modal-body {
    width: 375px;
    height: 540px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    background: $event-color-center
      url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fevent-2022free%2F2022free-content.png?alt=media&token=9f44290c-ca3e-48d8-a093-70003243a1a3");
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: left;
    padding: 24px;
    display: flex;
  }
  button.primary {
    width: 80px;
    height: 40px;
    margin: auto 0 0 auto;
    border-radius: 20px;
    border: solid 1px $event-color-default !important;
    color: $event-color-default;
    background-color: transparent !important;
    line-height: 19px;
    font-weight: 500;
    font-size: 14px;
  }
  button.close {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: transparent;
    &::before,
    &::after {
      position: absolute;
      top: 12px;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: $event-color-default;
      background-color: var(--default-color);
      content: "";
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}

// 아이폰6 이하 해상도 기기에서 배너 글씨가 잘리는것을 방지 + 모달 대응을 위해 부득이하게 미디어쿼리를 넣었습니다 ㅠ.ㅠ

@media (max-width: 375px) {
  .event-left {
    display: none;
  }

  .event-right {
    background-color: $event-color-center;
  }

  .modal {
    padding-left: 16px;
    padding-right: 16px;
    .modal-body {
      width: 100%;
      padding: 16px;
      height: 80%;
      // display: relative;
    }
  }
  button.primary {
    display: none;
  }
}
</style>
