import inipay from './inipay';

export default {
  install: function(Vue) {
    let Inipay = new inipay({
      signKey: (process.env.NODE_ENV === 'production') ? "UDF1c3B2WmV3WVkyck9QSHV0b3ZjZz09" : "SU5JTElURV9UUklQTEVERVNfS0VZU1RS",
      mid: (process.env.NODE_ENV === 'production') ? "supermemb3" : "INIpayTest"
      // signKey: "UDF1c3B2WmV3WVkyck9QSHV0b3ZjZz09",
      // mid: "supermemb3"
    })
    Object.defineProperty(Vue.prototype, '$inipay', { value: Inipay });
  }
}