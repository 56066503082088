export default {
  state: {
    data: {},
  },
  mutations: {
    setAiData(state, payload) {
      state.data = payload;
    },
  },
  getters: {
    aiData(state) {
      return state.data;
    },
  },
};
