import Vue from 'vue';
import Router from 'vue-router';

import Header from '@/components/header/Header';
import { EventBus } from '@/components/shared/event-bus';

import store from '../store';

Vue.use(Router);

const originTitle = document.title;

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/',
      name: process.env.VUE_APP_TARGET === 'campaign' ? 'Matching' : 'Landing',
      components: {
        default: () =>
          process.env.VUE_APP_TARGET === 'campaign'
            ? import('@/views/landing/Matching')
            : import('@/views/landing/Landing'),
        header: Header,
      },
    },
    {
      path: '/terms',
      name: 'Terms',
      components: {
        default: () => import('@/views/landing/Terms'),
        header: Header,
      },
    },
    {
      path: '/privacy',
      name: 'Privacy',
      components: {
        default: () => import('@/views/landing/Privacy'),
        header: Header,
      },
    },
    {
      path: '/faq',
      name: 'Faq',
      components: {
        default: () => import('@/views/landing/Faq'),
        header: Header,
      },
    },
    {
      path: '/rank',
      name: 'rank',
      components: {
        default: () => import('@/views/landing/Rank'),
        header: Header,
      },
    },
    {
      path: '/rank/show',
      name: 'rankshow',
      components: {
        default: () => import('@/views/landing/RankShow'),
        header: Header,
      },
    },
    {
      path: '/test',
      name: 'Test',
      components: {
        default: () => import('@/views/landing/Test'),
        header: Header,
      },
    },
    {
      path: '/signup',
      name: 'Signup',
      components: {
        default: () => import('@/views/login/Signup'),
        header: Header,
      },
    },
    {
      path: '/signin',
      name: 'Signin',
      components: {
        default: () => import('@/views/login/Signin'),
        header: Header,
      },
    },
    {
      path: '/passwdch',
      name: 'Passwdch',
      components: {
        default: () => import('@/views/login/Passwdch'),
        header: Header,
      },
    },
    {
      path: '/regads',
      name: 'Regads',
      components: {
        default: () => import('@/views/company/Regads'),
        header: Header,
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/naver',
      name: 'Naver',
      components: {
        default: () => import('@/views/login/Naver'),
        header: Header,
      },
    },
    {
      path: '/leadcomplete',
      name: 'LeadComplete',
      components: {
        default: () => import('@/views/landing/LeadComplete'),
        header: Header,
      },
    },
    {
      path: '/formsubmit',
      name: 'formsubmit',
      async beforeEnter(to, from, next) {
        store.commit(
          'setMsg',
          '요청이 완료되었습니다. 담당자가 확인 후 작성해주신 이메일로 서비스 소개서를 보내드립니다.'
        );
        store.commit('setShowSnackbar', true);
        next('/');
      },
    },
    {
      path: '/receipt/:id',
      name: 'Receipt',
      components: {
        default: () => import('@/views/receipt/Receipt'),
      },
    },
    {
      path: '/cardform/:id',
      name: 'CardForm',
      components: {
        default: () => import('@/views/cardform/CardForm')
      },
      beforeEnter: (to, from, next) => {
        // `id`가 숫자인지 확인
        if (/^\d+$/.test(to.params.id)) {
          next();
        } else {
          next('/cardform/' + to.params.id.replace(/\D.*$/, ""));
        }
      }
    },
    {
      path: '/aibaek/',
      name: 'Ai',
      components: {
        default: () => import('@/views/ai/Ai'),
      },
      meta: {
        title: 'AI 백선생이 사장님의 매장을 분석해드려유~',
      },
    },
    {
      path: '/ai/analysis-request/:id',
      name: 'Ai-Analysis-Request',
      components: {
        default: () => import('@/views/ai/AnalysisRequest'),
      },
      meta: {
        title: 'AI 백선생이 사장님의 매장을 분석해드려유~',
      },
    },
    {
      path: '/ai/analysis-result',
      name: 'Ai-Analysis-Result',
      components: {
        default: () => import('@/views/ai/AnalysisResult'),
      },
      meta: {
        title: 'AI 백선생이 사장님의 매장을 분석해드려유~',
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      async beforeEnter(to, from, next) {
        await store.dispatch('logout');
        next('/');
      },
    },
    {
      path: '/guide/agency/store',
      name: 'AgencyStore',
      components: {
        default: () => import('@/views/guide/agency/Store'),
        header: Header,
      },
    },
    {
      path: '/guide/agency/product',
      name: 'AgencyProduct',
      components: {
        default: () => import('@/views/guide/agency/Product'),
        header: Header,
      },
    },
    {
      path: '/guide/agency/press',
      name: 'AgencyPress',
      components: {
        default: () => import('@/views/guide/agency/Press'),
        header: Header,
      },
    },
    {
      path: '/guide/campaign/store',
      name: 'Campaign1',
      components: {
        default: () => import('@/views/guide/campaign/Store'),
        header: Header,
      },
    },
    {
      path: '/guide/campaign/product',
      name: 'Campaign2',
      components: {
        default: () => import('@/views/guide/campaign/Product'),
        header: Header,
      },
    },
    {
      path: '/guide/campaign/press',
      name: 'Campaign3',
      components: {
        default: () => import('@/views/guide/campaign/Press'),
        header: Header,
      },
    },
    {
          // NOTE : members에서 맥형 광고를 위해 사용하는 디테일 페이지 SideBar 제거를 위해 새 path로 지정
          path: '/partner-detail',
          name: 'PartnerDetail',
          component: () => import('@/views/company/PartnerDetail'),
    },
    {
      // path: '/company/:id',
      path: '/company',
      name: 'Company',
      components: {
        default: () => import('@/views/company/Company'),
        header: Header,
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/views/company/Dashboard'),
        },
        {
          path: 'mypage',
          name: 'Mypage',
          component: () => import('@/views/company/Mypage'),
        },
        {
          path: 'modads',
          name: 'Modads',
          component: () => import('@/views/company/Modads'),
        },
        {
          path: 'contract',
          name: 'Contract',
          component: () => import('@/views/company/Contract'),
        },
        {
          path: 'report',
          name: 'Report',
          component: () => import('@/views/company/Report'),
        },
        {
          path: 'pay',
          name: 'Pay',
          component: () => import('@/views/company/Pay'),
        },
        {
          path: 'settlement',
          name: 'Settlement',
          component: () => import('@/views/company/Settlement'),
        },
        {
          path: 'charge',
          name: 'CompanyCharge',
          component: () => import('@/views/company/Charge'),
        },
        {
          path: 'editinfo',
          name: 'Editinfo',
          component: () => import('@/views/company/Editinfo'),
        },
        {
          path: 'admin',
          name: 'Admin',
          component: () => import('@/views/company/Admin'),
        },
        {
          path: 'report/detail',
          name: 'AdReportDetail',
          component: () => import('@/components/company/report/AdReportDetail'),
        }
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/regcam',
      name: 'Regcam',
      components: {
        default: () => import('@/views/campaign/Regcam'),
        header: Header,
      },
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: '/campaign',
      name: 'Campaign',
      components: {
        default: () => import('@/views/campaign/Campaign'),
        header: Header,
      },
      children: [
        {
          path: 'search',
          name: 'Search',
          component: () => import('@/views/campaign/Search'),
        },
        {
          path: 'proposition',
          name: 'Proposition',
          component: () => import('@/views/campaign/Proposition'),
        },
        {
          path: 'searchyoutube',
          name: 'SearchYoutube',
          component: () => import('@/views/campaign/SearchYoutube'),
        },
        {
          path: 'propositionyoutube',
          name: 'PropositionYoutube',
          component: () => import('@/views/campaign/PropositionYoutube'),
        },
        {
          path: 'autoproposition',
          name: 'AutoProposition',
          component: () => import('@/views/campaign/AutoProposition'),
        },
        {
          path: 'charge',
          name: 'Charge',
          component: () => import('@/views/campaign/Charge'),
        },
        {
          path: 'list',
          name: 'CampaignList',
          component: () => import('@/views/campaign/CampaignList'),
        },
        {
          path: 'detail/:index',
          name: 'CampaignDetail',
          component: () => import('@/views/campaign/CampaignDetail'),
        },
        {
          path: 'editinfo',
          name: 'EditinfoCampaign',
          component: () => import('@/views/campaign/Editinfo'),
        },
        {
          path: 'searchtiktok',
          name: 'SearchTiktok',
          component: () => import('@/views/campaign/SearchTiktok'),
        }
      ],
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (store.getters.dirty) {
    store.commit('setShowDirty', true);
    store.commit('setDirty', false);
  } else if (
    requiresAuth &&
    !store.getters?.user?.email &&
    from.path !== '/signin'
  ) {
    store.commit('setReturnUrl', to.path);
    next('/signin');
  } else {
    if (store.getters?.user?.email && to.path === '/') {
      EventBus.$emit('update:point');
    }
    next();
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || originTitle;
  });
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    store.commit('setLoading', true);
  }
  next();
});

router.afterEach(() => {
  store.commit('setLoading', false);
});

export default router;
